<template>
  <div class="box">
    <div class="t-title">产品展示</div>
    <div class="banner">
      <img src="@/assets/images/loan/text.png" alt="" class="banner-text" />

      <div class="search">
        <input
          type="text"
          v-model="searchText"
          placeholder="请输入银行/贷款产品名称"
        />
        <div class="search-btn" @click="handleSearch()"></div>
      </div>
    </div>
    <div class="list">
      <div class="list-title">
        <p>
          共<span>{{ pagination.total }}</span
          >款产品
        </p>
        <!-- <div class="select">
          <van-field
            v-model="resultLabel"
            is-link
            readonly
            name="picker"
            placeholder="点击选择标签"
            @click="showPickerLabel = true"
          />
          <van-popup v-model="showPickerLabel" position="bottom">
            <van-picker
              show-toolbar
              :columns="columnsLabel"
              @confirm="onConfirmLabel"
              @cancel="showPickerLabel = false"
            />
          </van-popup>
        </div> -->
        <div class="select">
          <van-field
            v-model="resultCompany"
            is-link
            readonly
            name="picker"
            placeholder="点击选择企业"
            @click="showPickerCompany = true"
          />
          <van-popup v-model="showPickerCompany" position="bottom">
            <van-picker
              show-toolbar
              :columns="columnsCompany"
              @confirm="onConfirmCompany"
              @cancel="showPickerCompany = false"
            />
          </van-popup>
        </div>
      </div>
      <div class="wrap">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="50"
          :immediate-check="false"
        >
          <div
            class="item"
            v-for="item of listArr"
            :key="item.fpUuid"
            @click="handleClick(item)"
          >
            <div class="t-wrap">
              <!-- <img src="~@/assets/images/loan/bank.png" alt="" /> -->
              <img
                :src="
                  baseApi +
                  '/public/financial/dyna/loadFile?CMD=HTML&forceDownLoad=false&uuid=' +
                  item.fpCrfileUuid
                "
                alt="暂无图片"
              />
              <div class="info">
                <div class="col">
                  <div class="title">{{ item.fpProductName }}</div>
                  <div class="num">
                    已成功对接<span>{{ item.fpDockingNum }}</span
                    >笔
                  </div>
                </div>
                <div class="col">
                  <div class="star">
                    <div class="text">产品评价</div>
                    <van-rate
                      :value="Number(item.fpProductSource) / 2"
                      :size="10"
                      allow-half
                      color="#ffd21e"
                      void-icon="star"
                      void-color="#eee"
                    />
                    <!-- <i v-for="(item, index) of 5" :key="index"></i> -->
                  </div>
                  <div class="star">
                    <div class="text">银行满意度</div>
                    <van-rate
                      :value="Number(item.fpBankSource) / 2"
                      :size="10"
                      allow-half
                      color="#ffd21e"
                      void-icon="star"
                      void-color="#eee"
                    />
                    <!-- <i v-for="(item, index) of 5" :key="index"></i> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="b-wrap">
              <div class="detail">
                <div class="text">贷款额度</div>
                <div class="num">{{ item.fpLoanAmount }}万元</div>
              </div>
              <div class="detail">
                <div class="text">担保方式</div>
                <div class="num">{{ item.fpAssureMeans }}</div>
              </div>
              <div class="detail">
                <div class="text">贷款期限</div>
                <div class="num">{{ item.fpLoanDeadline }}月</div>
              </div>
              <div class="detail">
                <div class="text">参考利率</div>
                <div class="num">
                  {{ item.fpReferenceRateStart }}%~{{
                    item.fpReferenceRateEnd
                  }}%
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listFinancialBankProducts,
  getToken,
  listEnterpriseLabelInfos,
  getImg,
} from "@/api/loan.js";
import { mapMutations } from "vuex";
export default {
  name: "Loan",
  data() {
    return {
      baseApi: "/prod-api",
      searchText: "",
      pagination: {
        current: 1,
        limit: 10,
        total: "",
      },
      applyArrLabel: "",
      applyArrCompany: "",
      listArr: [],
      showPickerLabel: false,
      showPickerCompany: false,
      resultLabel: "",
      resultCompany: "",
      columnsLabel: [],
      columnsCompany: [],
      labelArr: [],
      loading: false,
      finished: false,
      timer: null,
    };
  },
  async beforeCreate() {
    await getToken({ sign: new Date().getTime() }).then((res) => {
      this.SET_AUTHORIZATION(res.data);
    });
  },
  mounted() {
    this.getListFinancialBankProducts(true);
    this.getListEnterpriseLabelInfos();
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
  methods: {
    //获取企业标签信息
    getListEnterpriseLabelInfos() {
      listEnterpriseLabelInfos({
        openId: localStorage.getItem("openid"),
        // openId: "o3JQ06uMfbv9nWloUy-sWAczOCkA",
      }).then((res) => {
        if (res.success) {
          this.labelArr = res.data;
          res.data.forEach((item) => {
            this.columnsCompany.push(item.enterpriseName);
            this.resultCompany = this.columnsCompany[0];
            item.bliBqmc.forEach((item2) => {
              this.columnsLabel.push(item2);
            });
          });
          this.columnsLabel = [...new Set(this.columnsLabel)];
          this.resultLabel = this.columnsLabel[0];
          this.applyArrLabel = this.columnsLabel[0];
          const value = this.labelArr.filter(
            (item) => item.enterpriseName == this.columnsCompany[0]
          );
          this.applyArrCompany = value[0].bliBqmc;
        }
      });
    },
    onConfirmCompany(val) {
      const value = this.labelArr.filter((item) => item.enterpriseName == val);
      this.applyArrCompany = value[0].bliBqmc;
      this.resultCompany = val;
      this.showPickerCompany = false;
    },
    onConfirmLabel(val) {
      this.applyArrLabel = val;
      this.resultLabel = val;
      this.showPickerLabel = false;
    },
    ...mapMutations(["SET_AUTHORIZATION"]),
    getListFinancialBankProducts(flag = false) {
      if (flag) {
        this.pagination.current = 1;
      }
      listFinancialBankProducts({
        // applyUsers: [...this.applyArrCompany],
        applyUsers: "",
        // label: this.applyArrLabel,
        label: "",
        page: this.pagination.current,
        limit: this.pagination.limit,
        keyword: this.searchText,
        fpAuditFlag: "1",
      }).then((res) => {
        if (res.success) {
          if (!flag) {
            this.listArr = [...this.listArr, ...res.data];
          } else {
            this.listArr = res.data;
          }
          this.pagination.total = res.totalCount;
        }
      });
    },
    //查询
    handleSearch() {
      this.getListFinancialBankProducts(true);
    },
    handleClick(val) {
      this.$router.push({
        path: "./loanDetail",
        query: {
          uuid: val.fpUuid,
          company: this.resultCompany,
        },
      });
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.timer = setTimeout(() => {
        // for (let i = 0; i < 10; i++) {
        //   this.list.push(this.list.length + 1);
        // }

        // // 加载状态结束
        // this.loading = false;

        // // 数据全部加载完成
        // if (this.list.length >= 40) {
        //   this.finished = true;
        // }
        if (
          this.pagination.current * this.pagination.limit >=
          this.pagination.total
        ) {
          this.finished = true;
        } else {
          this.loading = false;
          this.pagination.current++;
          this.getListFinancialBankProducts();
        }
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index.scss";
</style>
